<script lang="ts">
  import { project } from "$lib/services/store";
  import { translations } from "$lib/data/translations";

  const t = translations as Record<string, any>;

  export let text: string = '';
  export let lang = $project?.settings?.lang ?? "en";
  export let lowercase = false;

  function translate(text: string, lang: string) {
    if (t[text]) {
      if (lowercase) {
        return t[text]?.[lang]?.toLowerCase() ?? text.toLowerCase();
      } else {
        return t[text]?.[lang] ?? text;
      }
    }
    return text;
  }

  let translated = translate(text, lang);
</script>

{translated}
